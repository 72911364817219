<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				New keyword
			</div>
		</div>

		<div>
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="submit"
			>
				<input-field
					name="Add a keyword"
					rules="required"
					type="text"
					v-model="keyword.keyword"
				/>
				<actions
					:actions="getActions"
					@actionClick="onActionClick"
				/>
			</validation-observer>
		</div>
	</div>
</template>

<script>
	import InputField          from '@/components/forms/InputField';
	import Actions             from '@/components/ui/Actions';
	import actionClick         from '@/mixins/actionClick';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import api                 from '@/services/api';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'New keyword'
		},
		components: {
			InputField,
			Actions
		},
		mixins: [actionClick, invalidFormMessage],
		data: () => ({
			keyword: {
				keyword: ''
			}
		}),
		computed: {
			getBreadcrumbRoute () {
				return '/admin/keywords';
			},
			getActions () {
				return {
					secondary: [
						{
							text: 'Cancel',
							route: this.getBreadcrumbRoute
						},
						{
							text: 'Create keyword',
							type: 'submit'
						}
					].filter(Boolean)
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/keywords',
						text: 'Keywords'
					},
					{
						path: '/admin/keywords/create',
						text: 'New keyword'
					}
				].filter(Boolean);
			}
		},
		created () {
			this.$store.commit('ui/setBreadcrumbs', {
				breadcrumbs: this.getBreadcrumbs
			});
			this.$store.commit('ui/setLoadingIsHidden');
		},
		methods: {
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				const keyword = await api.admin.createKeyword({
					keyword: this.keyword
				});
				await this.$router.push(`/admin/keywords/${keyword.id}`);
				this.$store.commit('ui/showNotification', {
					notification: 'The keyword has been created'
				});
			}
		}
	};
</script>
